import React, { useState, useEffect } from "react";
import { Popup, NavBar } from "antd-mobile";
import { Modal } from "antd";
import Header from "../components/visual/header";
import Footer from "../components/visual/footer";
import PDFViewer from "../components/visual/pdf-viewer-4";
import { FE_BASE_URI } from "../constants/const";

import agenda from "../assets/portada-agenda-1.png";
import bg from "../assets/bg_code.jpg";
import agenda2 from "../assets/agenda2.png";

const LandingPage = () => {
    const [modalVisible, setModalVisible] = useState(false); //Desktop
    const [isDrawerOpen, setIsDrawerOpen] = useState(false); // movil

    useEffect(() => {
        document.title = "littlecoders.dev. Aprende Python mientras organizas tu día";
    }, []);

    const closeModal = () => {
        setModalVisible(false);
    };


    return (
        <>
            <Header />
            <div
                style={{
                    fontFamily: "'Poppins', sans-serif",
                    lineHeight: '1.6',
                    color: '#333',
                    margin: '0 auto',
                    padding: '5px',
                }}
                className="container  mb-4"
            >
                <div className="p-4 my-4 text-center"
                    style={{
                        width: "100%", backgroundColor: "#5B88FA",
                        backgroundImage: `url(${bg})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}>
                    <img src={agenda2}
                        style={{ width: "90%" }}
                        alt="Agendas LittleCoders.dev"
                    />
                    <h2 style={{
                        fontSize: '1.5em',
                        color: '#fff',
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                    }}
                        className="mt-4">
                        "Aprende Python mientras organizas tu día."
                    </h2>
                </div>

                <h2 className="p-5 my-4 d-none" style={{
                    fontSize: '1.66em',
                    color: '#fff',
                    fontWeight: 'bold',
                    fontStyle: 'italic',
                    backgroundColor: "#5B88FA",
                    backgroundImage: `url(${bg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}>
                    "La agenda que hará que estudiantes se enamoren de la programación."
                </h2>

                <p>
                    Sabemos que la programación es una de esas asignaturas que asusta, pero tú tienes el poder de convertir el miedo en curiosidad.
                </p>

                <p>
                    Por eso hemos creado la <strong>Agenda Escolar Interactiva littlecoders.dev</strong>, una herramienta que combina una  <a
                        href="https://api2.destinodocente.es/portal/redirect?url=https://www.amazon.es/dp/B0DR7BGF7L&utm_source=home-lc-1-0">
                        <strong>agenda en papel</strong>
                    </a>
                    &nbsp;y una <a href="/"><strong>web para probar</strong> </a> los ejemplos y que hará que los estudiantes se organicen mejor mientras dan sus primeros pasos en el mundo de la programación.
                </p>
                {/* Imagen destacada */}
                <div className="mt-4 text-center banner-agenda" style={{ backgroundColor: "#FA824C" }} >
                    <div className="d-md-none">
                        <h2>
                            <a onClick={() => setIsDrawerOpen(true)} href="#">
                                <i className="fa-solid fa-magnifying-glass me-1"></i>Explora la Agenda littlecoders.dev
                            </a>
                        </h2>
                    </div>
                    <div className="d-none d-md-block">
                        <h3>
                            <a onClick={() => setModalVisible(true)} href="#">
                                <i className="fa-solid fa-magnifying-glass me-1"></i> Explora la Agenda littlecoders.dev
                            </a>
                        </h3>
                    </div>
                    <img
                        src={agenda}
                        alt="Agenda LittleCoders.dev"
                        style={{ width: "100%", borderRadius: "15px", transform: "rotate(-22deg)" }}
                        className="my-4"
                    />
                </div>

                <h3 className="mt-4 mb-2">¿Cómo puede ayudarte esta agenda?</h3>

                <ul className="m-2 topics mt-4" style={{ listStyle: 'none', fontSize: '1.1rem', padding: '0' }}>
                    <li>
                        <strong>Ahorras tiempo explicando lo básico.</strong> Cada semana los alumnos tendrán ejemplos de Python para probar en nuestro <a href="/playground" style={{ color: '#e6813a', textDecoration: 'none', fontWeight: "bold" }}>playground. </a>
                    </li>
                    <li>
                        <strong>Mejor organización, menos problemas para ti.</strong> Combina espacios para Horarios y tareas con ejercicios de programación.
                    </li>
                    <li>
                        <strong>Conocer entornos de programación.</strong> Apariencia basada en VS Code y Scrum
                    </li>
                </ul>

                <p className="mt-4">
                    Imagina el impacto que puedes tener: convertir a estudiantes en apasionados de la programación con avances cada semana.
                </p>

                <p>
                    No es solo una agenda. Es una forma de <strong>motivar a tus estudiantes</strong> con habilidades del futuro.
                </p>

                {/* Botón de compra */}
                <center>
                    <a
                        href="https://api2.destinodocente.es/portal/redirect?url=https://www.amazon.es/dp/B0DR7BGF7L&utm_source=home-lc-1-2"
                        className="button my-5 text-center"
                        style={{
                            display: 'block',
                            padding: '12px 24px',
                            backgroundColor: '#0d6efd',
                            color: 'white',
                            textDecoration: 'none',
                            borderRadius: '25px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            maxWidth: '320px',
                            fontSize: '1.25em',
                        }}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Consigue la agenda 2025-2026 <br></br> desde 12,50 €
                    </a>
                </center>

                <h3>
                    ¿Por qué es perfecta para tu clase?
                </h3>

                <p>
                    Porque sabemos que los profes como tú no solo enseñan asignaturas: inspiran, motivan y cambian vidas.
                </p>

                <p>
                    Con esta agenda, puedes ser esa chispa que despierte vocaciones en tecnología, una industria llena de oportunidades.
                </p>

                <hr className="my-4"></hr>
                <center>
                    <h4>
                        ¿Quieres la agenda para este curso 2024-2025?
                    </h4>
                </center>
                <a
                    href="https://api2.destinodocente.es/portal/redirect?url=https://www.amazon.es/dp/B0DNTNG591&utm_source=home-lc-1-3"
                    className="button mt-4"
                    style={{
                        display: 'block',
                        width: 'fit-content',
                        margin: '20px auto',
                        padding: '12px 24px',
                        backgroundColor: '#0d6efd',
                        color: 'white',
                        textDecoration: 'none',
                        borderRadius: '25px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}
                    rel="noreferrer"
                    target="_blank"
                >
                    Consigue la agenda 2024-2025 ahora
                </a>
            </div>
            <Footer />


            <Modal open={modalVisible} onCancel={closeModal} footer={null}
                style={{
                    top: 0,
                    padding: 0,
                    height: '99vh',
                    maxWidth: '100vw',
                }}
                bodyStyle={{
                    height: 'calc(100vh - 50px)',
                    overflowY: 'auto',
                }}>
                <div>
                    <PDFViewer url={FE_BASE_URI + "preview2425.pdf"} />
                </div>
            </Modal>

            <Popup
                visible={isDrawerOpen}
                getContainer={false} // para que funcione en mobile
                onMaskClick={() => {
                    setIsDrawerOpen(false);
                }}
                bodyStyle={{ width: "100%" }}
                position="left"
            >
                <NavBar
                    onBack={() => {
                        setIsDrawerOpen(false);
                    }}
                    className="back-bar"
                >
                    Explora la Agenda littlecoders.dev
                </NavBar>
                <div className="pt-1">
                    <PDFViewer url={FE_BASE_URI + "preview2425.pdf"} />
                </div>
            </Popup>
        </>
    );
};

export default LandingPage;
