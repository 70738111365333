// src/JavaScriptConsole.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Button, Toast, List as MList } from 'antd-mobile';
import { Card, Spinner } from 'react-bootstrap';
import { List, Drawer } from 'antd';
import Editor from '@monaco-editor/react';
import { FaAngleRight } from 'react-icons/fa';

import Header from './visual/header';
import Footer from './visual/footer';
import { examplesData } from '../data/examples';
import { getWeekNumber } from '../services/utils';

function JavaScriptConsole() {
  const { filename } = useParams();
  const [code, setCode] = useState('console.log("¡Hola, LittleCoder!");');
  const [output, setOutput] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  let navigate = useNavigate();
  const weekIndex = getWeekNumber(new Date());

  useEffect(() => {
    if (filename) {
      const fetchCode = async () => {
        try {
          const response = await fetch(`/${filename}.json`);
          if (!response.ok) {
            throw new Error('No se pudo cargar el código');
          }
          const data = await response.json();
          setCode('/*\n' + data.description + '\n*/\n\n' + data.code + '\n');
        } catch (error) {
          console.error('Error al cargar el código:', error);
          setCode('// Error al cargar el código. Prueba con tu propio código.');
        }
      };
      fetchCode();
    }
  }, [filename]);

  const runCode = async () => {
    setLoading(true);
    setOutput('');
    Toast.show({
      icon: 'loading',
      content: 'Ejecutando código...',
      duration: 0,
    });

    try {
      // Evaluamos el código JavaScript dentro del navegador
      const result = await eval(code);
      console.log("res",code, result);
      setOutput(!!result ? result : 'Código ejecutado con éxito.');
    } catch (err) {
      setOutput('Error: ' + err.toString());
    } finally {
      Toast.clear();
      setLoading(false);
    }
  };

  const onSelect = (keys, info) => {
    if (info.node.url) {
      setIsDrawerOpen(false);
      navigate("/playground/js/" + info.node.url.split('/').pop());
    }
  };

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="d-md-none py-2">
            <Button type="primary" onClick={() => setIsDrawerOpen(true)}>
              Ejemplos <FaAngleRight />
            </Button>
          </div>

          <nav className="col-md-4 col-lg-3 d-none d-md-block bg-light sidebar" style={{ height: "75vh" }}>
            <div className="pt-3">
              <h5>Ejemplos</h5>
              <List
                itemLayout="horizontal"
                dataSource={examplesData}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        item.id > weekIndex ? (
                          <span className='disabled'>{item.id}. {item.title}</span>
                        ) : (
                          <Link to={"/playground/js/" + item.key}>{item.id}. {item.title}</Link>
                        )
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
          </nav>

          <Drawer
            title="Ejemplos"
            placement="left"
            onClose={() => setIsDrawerOpen(false)}
            visible={isDrawerOpen}
            bodyStyle={{ padding: "10px", width: "100vw" }}
          >
            <MList>
              {examplesData.map(item => (
                <List.Item key={item.id} prefix={<i className="fab fa-js"></i>}>
                  {item.id > weekIndex ? (
                    <span className='disabled'>{item.id}. {item.title}</span>
                  ) : (
                    <Link
                      to={"/playground/js/" + item.key}
                      onClick={() => { setIsDrawerOpen(false); }}
                    >
                      {item.id}. {item.title}
                    </Link>
                  )}
                </List.Item>
              ))}
            </MList>
          </Drawer>

          <main className="col-md-8 ms-sm-auto col-lg-9 px-md-4">
            <div className="content mt-2">
              <div className="container- my-3" style={{ height: "65vh" }}>
                <Card className="mt-2 bg-dark text-white">
                  <Card.Header>
                    <div className="float-left mt-2" style={{ display: "inline-block" }}>
                      <h5>
                        <i className="fab fa-js"></i> {filename ? filename : "littlecoders.js"}
                      </h5>
                    </div>
                    <div className='float-right' style={{ display: "inline" }}>
                      <Button color="primary" onClick={runCode}>
                        <i className="fas fa-play"></i>
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {loading ? (
                      <div className="text-center my-5">
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      <>
                        <Editor
                          height="33vh"
                          defaultLanguage="javascript"
                          value={code}
                          onChange={(value) => setCode(value)}
                        />
                        <h5>Salida:</h5>
                        <pre className="bg-secondary p-3 text-white code-output">{output}</pre>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default JavaScriptConsole;
