import logo from "../../assets/logo.png";


function Header() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top- shadow-sm">
            <div className="container" >
                <a className="navbar-brand-d-flex align-items-center" href="/">
                    <img src={logo} alt="Littlecoders.dev logo" className="rounded-circle- me-2" style={{ width: 'auto', height: '40px' }} />
                </a>
                <div className="playground-link" style={{ position: 'absolute',  top: '50%' }}>
                    <a href="/playground" style={{  textDecoration: 'none' }}>
                        <h5 style={{ color: '#e6813a', textDecoration: 'none' }}>
                            <strong>playground
                                </strong>&nbsp;
                            <i className="fas fa-play"></i>
                        </h5>
                    </a>
                </div>
            </div>
        </nav>
    );
}
export default Header;