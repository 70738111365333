// src/PythonConsole.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Button, Toast, List as MList } from 'antd-mobile';
import { Card, Spinner } from 'react-bootstrap';
import { List, Drawer } from 'antd';
import Editor from '@monaco-editor/react';
import { FaAngleRight } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

import Header from './visual/header';
import Footer from './visual/footer';
import { examplesData } from '../data/examples';
import { getWeekNumber } from '../services/utils';


function getPyFilename(filename) {
  // Validate the input to ensure it is a string
  if (typeof filename !== 'string') {
    throw new Error('Invalid input: filename must be a string');
  }

  // Use a regular expression to remove the leading number and underscore
  const transformedFilename = filename.replace(/^\d+_/, '');

  // Replace hyphens with underscores
  const finalFilename = transformedFilename.replace(/-/g, '_');

  // Append the .py extension
  return `${finalFilename}.py`;
}

function PythonConsole() {
  const { filename } = useParams();
  const [pyodide, setPyodide] = useState(null);
  const [code, setCode] = useState('print("¡Hola, LittleCoder!")');
  const [output, setOutput] = useState('');
  const [loading, setLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [codeComment, setCodeComment] = useState('Uso de la la función print() para mostrar un mensaje en pantalla.');


  let navigate = useNavigate();
  const weekIndex = getWeekNumber(new Date());

  useEffect(() => {
    const loadPyodide = async () => {
      try {
        const pyodide = await window.loadPyodide({
          indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.23.4/full/',
        });
        setPyodide(pyodide);
      } catch (error) {
        console.error('Error al cargar Pyodide:', error);
      } finally {
        setLoading(false);
      }
    };

    if (!window.loadPyodide) {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/pyodide/v0.23.4/full/pyodide.js';
      script.onload = loadPyodide;
      document.body.appendChild(script);
    } else {
      loadPyodide();
    }

    console.log("week", getWeekNumber(new Date()))
  }, []);

  useEffect(() => {
    if (filename) {
      const fetchCode = async () => {
        try {
          //TODO: resolverlo mejor en un subdir, pero no funciona por permisos o CORS
          const response = await fetch(`/${filename}.json`);
          if (!response.ok) {
            throw new Error('No se pudo cargar el código');
          }
          const data = await response.json();
          setCode('"""\n' + data.description + '\n"""\n\n' + data.code + '\n');
          setCodeComment(data.description);
        } catch (error) {
          console.error('Error al cargar el código:', error);
          setCode('# Error al cargar el código. Prueba con tu propio codigo y aprende.');
        }
      };
      fetchCode();
    }
  }, [filename]);

  const runCode = async () => {
    if (!pyodide) return;

    try {
      setOutput('');
      Toast.show({
        icon: 'loading',
        content: 'Ejecutando código...',
        duration: 0,
      });

      await pyodide.runPythonAsync(`
import sys
from io import StringIO
sys.stdout = sys.stderr = StringIO()
      `);

      await pyodide.runPythonAsync(code);

      const stdout = await pyodide.runPythonAsync('sys.stdout.getvalue()');
      setOutput(stdout);
    } catch (err) {
      setOutput(err.toString());
    } finally {
      Toast.clear();
    }
  };


  // Manejador de selección del árbol
  const onSelect = (keys, info) => {
    if (info.node.url) {
      setIsDrawerOpen(false); // Cierra el drawer en móvil tras seleccionar
      navigate("/playground/" + info.node.url.split('/').pop());  // "2_variables");
    }
  };

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={(filename? "/playground/" + filename : "/playground/")}
        />
        <meta name="description" content={filename ? getPyFilename(filename) + " :" + codeComment:""} />
        <title>littlecoders.dev playground: {filename ? getPyFilename(filename) : "littlecoders.py"}</title>
      </Helmet>
      <Header />
      <div className="container-fluid">
        <div className="row">
          {/* Botón Hamburguesa para móvil */}
          <div className="d-md-none py-2">
            <Button type="primary" onClick={() => setIsDrawerOpen(true)}>
              Ejemplos <FaAngleRight />
            </Button>
          </div>

          {/* Sidebar desktop */}
          <nav className="col-md-4 col-lg-3 d-none d-md-block bg-light sidebar" style={{ height: "75vh" }}>
            <div className="pt-3">
              <h5>Ejemplos</h5>
              <List
                itemLayout="horizontal"
                dataSource={examplesData}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        item.id > weekIndex ? (
                          <span className='disabled'>{item.id}. {item.title}</span>
                        ) : (
                          <Link to={"/playground/" + item.key}>{item.id}. {item.title}</Link>
                        )
                      }
                    //description={`ID: ${item.id}`}
                    />
                  </List.Item>
                )}
              />
            </div>
          </nav>

          {/* Drawer para el Sidebar en móvil */}
          <Drawer
            title="Ejemplos"
            placement="left"
            onClose={() => setIsDrawerOpen(false)}
            visible={isDrawerOpen}
            bodyStyle={{ padding: "10px", width: "100vw" }}
          >
            {/*
            <Tree
              treeData={examplesData.map((item) => ({
                title: item.title,
                key: item.key,
                url: item.url,
              }))}
              onSelect={onSelect}
              defaultExpandAll
              showLine
            />
            */}

            <MList>
              {examplesData.map(item => (
                <List.Item key={item.id} prefix={<i className="fab fa-python"></i>}>
                  {
                    item.id > weekIndex ? (
                      <span className='disabled'>{item.id}. {item.title}</span>
                    ) : (
                      <Link
                        to={"/playground/" + item.key}
                        onClick={() => {
                          setIsDrawerOpen(false);
                        }}
                      >
                        {item.id}. {item.title}
                      </Link>
                    )
                  }
                </List.Item>
              ))}
            </MList>
          </Drawer>

          {/* Main Content */}
          <main className="col-md-8 ms-sm-auto col-lg-9 px-md-4">
            <div className="content mt-2">
              <div className="container- my-3" style={{ height: "65vh" }}>
                <Card className="mt-2">
                  <Card.Header>
                    <div className="float-left mt-2" style={{ display: "inline-block" }}>
                      <h5>
                        <i className="fab fa-python"></i> {filename ? getPyFilename(filename) : "littlecoders.py"}
                      </h5>
                    </div>
                    <div className='float-right' style={{ display: "inline" }}>
                      <Button color="primary" onClick={runCode}>
                        <i className="fas fa-play"></i>
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {loading ? (
                      <div className="text-center my-5">
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      <>
                        <Editor
                          height="33vh"
                          defaultLanguage="python"
                          value={code}
                          onChange={(value) => setCode(value)}
                        //theme="vs-dark"
                        />
                        {/*
                        
                        <div className="text-center my-3">
                          <Button color="primary" size="large" onClick={runCode}>
                            <i className="fas fa-play me-2"></i> Play
                          </Button>
                        </div>
                        */}

                        <h5>Salida:</h5>
                        <pre className="bg-secondary p-3 text-white code-output">{output}</pre>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </div>
          </main>
        </div>
      </div>

      <Footer />
    </>

  );
}

export default PythonConsole;
